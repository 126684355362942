@mixin media_mobile {
  @media screen and (max-width: 791px) {
    @content();
  }
}

@mixin media_not_mobile {
  @media screen and (min-width: 792px) {
    @content();
  }
}
