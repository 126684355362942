@import "variables";

div.logos {
  margin-top: 90px;
  div.column {
    text-align: center;
  }
}

div#header,
div.site-header {
  position: relative;
  height: 800px;

  @media screen and (min-width: 1800px) {
    height: 1000px;
  }

  div {
    &.video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
      }

      &::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
      }

      //   &::after {
      //     content: " ";
      //     position: absolute;
      //     bottom: 0;
      //     left: 0;
      //     width: 100%;
      //     height: 30%;
      //     background: linear-gradient(
      //       to bottom,
      //       rgba(255, 255, 255, 0) 0%,
      //       rgba(255, 255, 255, 0.4) 50%,
      //       rgba(255, 255, 255, 1) 100%
      //     );
      //   }
    }

    &.column {
      position: relative;
      z-index: 2;
    }

    &.background {
    }

    &#header-left {
      height: 100%;
      position: relative;

      div.header-arrow {
        position: absolute;
        bottom: 30px;
        left: 15px;

        img {
          width: 30px;
        }
      }
    }
  }

  img {
    &.logo {
      width: 60px;
      margin-top: 4rem;
      filter: invert(100%);
    }
  }

  h1 {
    color: #fff;
    line-height: 1.25;
    font-size: 3.75rem;
    text-shadow: 0 0 4rem rgba(0, 0, 0, 1);

    @media screen and (max-width: 991px) {
      font-size: 2.9rem;
    }
  }

  p {
    font-size: 1.2rem;
    margin-top: 15px;
    color: rgba(255, 255, 255, 0.7);
    text-shadow: 0 0 4rem rgba(0, 0, 0, 1);
  }

  div.cta {
    @include media_mobile {
      flex-direction: column;
    }

    a.button {
      margin-top: 4rem;
      font-size: 1.1rem;

      &.is-black {
        background-color: transparent;
        border-bottom: 1px solid #fff;
        margin-top: 3.8rem;
        margin-left: 1.25rem;
        padding-left: 0;
        padding-right: 0;

        @include media_mobile {
          margin: 0;
          margin-right: 0.5rem;
        }
      }
    }
  }

  a.hamburger {
    width: 30px;
    float: right;
    margin-top: 4.15rem;

    div {
      width: 100%;
      height: 2px;
      margin-bottom: 5px;
      background-color: #fff;
    }

    &.open {
      div {
        opacity: 0.5;
      }
    }
  }
}

div#header {
  div#header-left,
  div.column.half-left {
    a.hamburger {
      display: none;
    }
  }

  @media (min-width: 769px) {
    min-height: 600px;

    div {
      &.header-hero {
        position: absolute;
        top: 57%;
        left: 15px;
        transform: translateY(-50%);
      }
    }
  }

  @media (max-width: 768px) {
    height: auto !important;

    div {
      &.half-left {
        display: table;
      }

      &#header-left,
      &.column.half-left {
        width: 100%;

        a.hamburger {
          display: table;
          margin-right: 10px;
        }
      }

      &.column.half-right {
        a.hamburger {
          display: none;
        }
      }

      &.header-hero {
        margin-top: 6rem;
      }

      &.background {
        height: 245px;
        margin-top: 30px;
      }

      &.header-arrow {
        display: none;
      }
    }
  }
}

div.site-header {
  text-align: center;
  height: auto;

  img.logo {
    filter: none;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }

  a.hamburger {
    margin-top: 2.6rem;

    div {
      background-color: #000;
    }
  }
}

div#footer {
  margin-top: 90px;

  @media (max-width: 768px) {
    margin-top: 60px;
  }

  div {
    &.logo {
      position: relative;
      float: left;
      margin-right: 20px;
      width: 75px;
      height: 75px;
      background: #000;

      img {
        width: 45px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.content {
      margin-top: 9px;

      @media (max-width: 991px) and (min-width: 490px) {
        margin-top: -5px;
      }

      @media (max-width: 489px) {
        margin-top: 0;
      }

      span {
        font-weight: 700;
        font-size: 22px;
        text-transform: uppercase;
        font-family: "Playfair Display", $fonts;
        margin-bottom: 0;
      }

      p {
        margin-top: -3px;
      }

      @media (max-width: 768px) {
        span {
          font-size: 18px;
        }

        p {
          font-size: 15px;
        }
      }
    }

    &.actions {
      @media (min-width: 769px) {
        text-align: right;
        position: relative;

        a.button {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }

      @media (max-width: 768px) {
        a.button {
          text-align: left;
        }
      }
    }

    &#gopherlabs,
    &#gopherlabs:hover {
      color: #363636;
      -webkit-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
    }

    &#gopherlabs {
      display: inline-block;
      width: 100%;
      background-color: #f6f7f8;
      padding: 23px 0 20px 0;
      margin-top: 30px;
      text-align: center;

      &:hover {
        background-color: rgb(233, 237, 240);
      }

      span.text {
        vertical-align: text-bottom;
      }

      img {
        vertical-align: middle;
        width: 35px;
      }
    }
  }
}
