@charset "utf-8";

$fonts: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  "Helvetica", "Arial", sans-serif;
$generic_transition: all 0.15s ease-out;

// Colors
$primary_color: #fc1160;
$accent_color: #ffd308;
$accent_text_color: #2d2d2d;
$primary_highlight_color: #fd587f;
$accent_highlight_color: #ffeb30;
$primary_text_color: #fff;
$text_color: #333;
$text_color_light: rgb(100, 100, 100);

@mixin hipfont() {
  font-family: "co-headline", $fonts;
}

@import "variables";
@import "container";
@import "../../node_modules/bulma/sass/utilities/initial-variables";
$primary: $primary_color;
$primary-invert: #fff;
@import "../../node_modules/bulma/sass/utilities/_all";
@import "../../node_modules/bulma/sass/base/_all";
@import "../../node_modules/bulma/sass/elements/button";
@import "../../node_modules/bulma/sass/elements/tag";
@import "../../node_modules/bulma/sass/elements/notification";
@import "../../node_modules/bulma/sass/components/navbar";
@import "../../node_modules/bulma/sass/components/card";
@import "../../node_modules/bulma/sass/grid/_all";
@import "../../node_modules/bulma/sass/elements/form";

@import "header-footer";

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: "Lato", $fonts;
  background: #000;
  font-size: 16px;
}

html,
body {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-family: "Playfair Display", $fonts;
  font-weight: 700;
}

h1,
.h1 {
  font-size: 3rem;
}

h2,
.h2 {
  font-size: 30px;
}

h3,
.h3 {
  font-size: 24px;
}

h4,
.h4 {
  font-size: 18px;
}

h5,
.h5 {
  font-size: 14px;
}

h6,
.h6 {
  font-size: 12px;
}

p {
  font-size: 1.25rem;
}

a {
  color: #004ac1;
}

a.button {
  font-family: "Lato", $fonts;
  font-weight: 700;
  border-radius: 0 !important;
  // border-color: #000;
  border-width: 2px;
  text-transform: uppercase;
  font-size: 15px;
  height: auto;
  padding: 7px 30px;
}

div.content-wrapper {
  background: #fff;
  transition: all 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow-x: hidden;

  &.hamburger-opened {
    transition: all 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: scale(0.8);
    opacity: 0.1;
  }
}

div.page {
  font-size: 18px;

  p {
    margin-bottom: 30px;
  }

  ul {
    list-style-type: disc;
    margin-left: 17px;
  }

  div {
    &.share {
      float: right;
      margin-top: 25px;
    }

    &.SocialMediaShareButton {
      // display: table;
      // float: left;
    }

    &.title-bar {
      padding-top: 25px;

      &:before {
        content: " ";
        display: block;
        width: 80px;
        height: 3px;
        background: #000;
      }

      p {
        font-family: "Lato", $fonts;
        font-style: italic;
      }
    }
  }
}

div#index-hero {
  position: relative;
  margin: 7rem 0;

  @media (max-width: 768px) {
    margin: 60px 0 30px 0;
  }

  div.bg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    color: #f6f7f8;
    font-size: 315px;
    z-index: -1;
  }

  div {
    &.is-narrow {
      max-width: 290px;
      padding-right: 40px;
    }
  }

  img.rounded {
    border-radius: 50%;
    margin-top: -5px;
    box-shadow: 0 10px 44px -5px rgba(51, 55, 98, 0.4);
  }

  h1 {
    font-size: 43px;
    line-height: 43px;
    margin-bottom: 30px;
  }

  p {
    padding-left: 30px;
  }
}

div.home-services {
  margin: 0 -6rem;
  pointer-events: none;

  &:hover div.column:not(:hover) {
    opacity: 0.5 !important;
    transition: $generic_transition !important;
  }

  div.column {
    width: calc(50% - 2rem);
    height: 175px;
    position: relative;
    padding: 0;
    margin: 1rem;
    overflow: hidden;
    border-radius: 8rem;
    pointer-events: all;
    transition: $generic_transition !important;

    @include media_mobile {
      width: unset;
      border-radius: 0;
    }

    // &::after {
    //   content: " ";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: calc(100%);
    //   height: calc(100%);
    //   box-sizing: border-box;
    //   border: 1rem solid #fff;
    //   border-radius: 1rem;
    // }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      filter: grayscale(0.5);
      width: 100%;
      min-height: 100%;
      transition: $generic_transition, transform 0.25s ease-out;
    }

    div.content {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.45);

      a {
        display: block;
        width: 100%;
        height: 100%;
      }

      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: "Playfair Display", $fonts;
        color: #fff;
        text-align: center;
        font-size: 30px;
        text-shadow: 0 0 2rem rgba(0, 0, 0, 1);
      }
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 15rem 15rem rgba(255, 255, 255, 0.2);
      z-index: 3;
      transition: $generic_transition;

      div.content {
        background: rgba(0, 0, 0, 0.2);
        transition: $generic_transition;

        p {
          padding: 2rem 4rem;
          background: #fff;
          color: #000;
          text-shadow: 0 0 2rem rgba(0, 0, 0, 0);
          border-radius: 15rem;
          transition: $generic_transition;
        }
      }

      img {
        transform: translate(-50%, -50%) scale(1.2);
        filter: none;
        transition: $generic_transition, transform 0.35s ease-out;
      }
    }
  }
}

div.page-header {
  width: 100%;
  height: 250px;
  background-size: cover;
  margin: 20px 0 30px 0;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
}

div.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: 10000;

  div.close {
    position: absolute;
    right: 60px;
    top: 60px;
    width: 30px;
    height: 30px;

    @media (max-width: 500px) {
      top: 45px;
      right: 30px;
    }

    span {
      position: absolute;
      display: block;
      width: 100%;
      height: 3px;
      background: white;
      opacity: 0;
      transition: 0.2s all ease-out;

      &.first {
        transform: rotate(45deg);
      }

      &.second {
        transform: rotate(-45deg);
      }
    }
  }

  div.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 37px;
    text-align: center;
    opacity: 0;
    transition: 0.2s all ease-out;
    margin-top: 60px;

    ul li {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: #fff;
    }
  }

  &.open {
    visibility: visible;
    opacity: 1;

    div.close {
      span {
        opacity: 1;
        transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition-delay: 0.25s;
      }
    }

    div.content {
      opacity: 1;
      transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition-delay: 0.25s;
      margin-top: 0;
    }
  }
}

// PSWP
img.pswp__img {
  max-width: 80%;
  max-height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pswp__bg {
  opacity: 1 !important;
}

ul.gallery {
  margin: 0 !important;

  li {
    list-style: none;
    margin-right: 15px;
    margin-bottom: 10px;
  }
}

// Tabs
.tab-list {
  // border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  border: solid transparent;
}

.tab-list-active {
  background-color: #000;
  border: solid #000;
  color: #fff;
}

div#services {
  padding: 30px 0 60px 0;
  text-align: center;
}

div#index-stats {
  position: relative;
  padding-bottom: 45px;
  padding-top: 45px;
  margin: 4rem 0;

  div.gradient__top {
    background: #efeeee;
    background: linear-gradient(180deg, #fff 0, hsla(0, 0%, 100%, 0));
    height: 30%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(https://images.unsplash.com/photo-1603466182843-75f713ba06b3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80)
      no-repeat center top;
    background-size: cover;
    opacity: 0.3;
  }

  &::after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  div.container {
    position: relative;
    z-index: 1;
  }

  div.title {
    text-align: center;
  }

  p.title-p {
    font-size: 1.5rem;
    margin-top: 2rem;
    text-align: center;
  }

  div.columns {
    margin-top: 10rem;

    div.column {
      text-align: center;

      svg {
        fill: red;
        width: 5rem;
        height: 5rem;
        margin-bottom: 1rem;
      }

      p.number {
        font-size: 2.75rem;
        font-weight: 700;
      }

      p.label {
        max-width: 210px;
        margin: auto;
        margin-top: 0.25rem;
        font-weight: 300;
        font-size: 18px;
      }
    }
  }

  div.columns {
    @include media_mobile {
      display: flex;
      justify-content: center;
    }

    div.column {
      @include media_mobile {
        width: 50%;
        flex-basis: unset;
      }
    }
  }
}

.section__logos {
  background-color: #fff;
  text-align: center;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z' fill='%23c5c5c5' fill-opacity='.4'/%3E%3C/svg%3E");
  overflow-x: hidden;
  padding: 14rem 0 10rem 0;
  position: relative;
  text-align: center;

  &::before {
    content: " ";
    background: #efeeee;
    background: linear-gradient(180deg, #fff 0, hsla(0, 0%, 100%, 0));
    height: 40%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 1rem;
    width: 100%;
    z-index: 1;
  }

  &::after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  & > div {
    position: relative;
    z-index: 2;
  }

  div.columns {
    @include media_mobile {
      justify-content: center;
    }

    div.column {
      @include media_mobile {
        width: 50%;
        flex-basis: unset;
      }
    }
  }

  h1 {
    margin-top: -4rem;
  }

  p {
    margin-bottom: 4rem;
  }
}
