.container,
.container-half {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container {
  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }

  &.wide {
    @media (min-width: 768px) {
      width: 1050px;
    }

    @media (min-width: 992px) {
      width: 1270px;
    }

    @media (min-width: 1200px) {
      width: 1470px;
    }
  }

  &-half {
    @media (min-width: 768px) {
      width: 375px;
    }

    @media (min-width: 992px) {
      width: 485px;
    }

    @media (min-width: 1200px) {
      width: 585px;
    }

    &.half-left {
      float: right;
    }
  }
}

.column {
  &.half-right {
    padding-left: 0;
  }

  &.half-left {
    padding-right: 0;
  }
}
